<template>
  <ion-page>
    <ion-content :fullscreen="true">
        <ion-grid>
            <ion-row v-if="roles.includes('EFIR')">
                <ion-col>
                    <button on-touch="router.push('/efir')" type="button" class="button-native ion-color ion-color-primary button-type" @click="() => router.push('/efir')">
                        Прямий єфір
                    </button>
                </ion-col>
            </ion-row>
            <ion-row>
                <ion-col>
                    <button on-touch="router.push('/news')" type="button" class="button-native ion-color ion-color-primary button-type" @click="() => router.push('/news')">
                        Новини
                    </button>
                </ion-col>
            </ion-row>
            <ion-row v-if="roles.includes('ROLE_POST_STREEM')">
                <ion-col>
                    <button on-touch="router.push('/stream')" type="button" class="button-native ion-color ion-color-primary button-type" @click="() => router.push('/stream')">
                        Почати стрім
                    </button>
                </ion-col>
            </ion-row>
            <ion-row v-if="roles.includes('ROLE_POST_3') || roles.includes('ROLE_POST_5')">
                <ion-col>
                    <button on-touch="isOpenRef = true" type="button" class="button-native ion-color ion-color-primary button-type" @click="() => isOpenRef = true">
                        Додати новину
                    </button>
                </ion-col>
            </ion-row>
            <ion-row v-if="roles.includes('ROLE_POST_3') || roles.includes('ROLE_POST_5')">
                <ion-col>
                    <button on-touch="router.push('/load')" type="button" class="button-native ion-color ion-color-primary button-type" @click="() => router.push('/load')">
                        Завантажити відео/аудіо/зображення
                    </button>
                </ion-col>
            </ion-row>
            <ion-row>
                <ion-col>
                    <button on-touch="router.push('/videos')" type="button" class="button-native ion-color ion-color-primary button-type" @click="() => router.push('/videos')">
                        Мої відео
                    </button>
                </ion-col>
            </ion-row>
            <ion-row v-if="roles.includes('ROLE_POST_STREEM')">
                <ion-col>
                    <button on-touch="router.push('/stream?gp=1')" type="button" class="button-native ion-color ion-color-danger button-type" @click="() => router.push('/stream?gp=1')">
                        Надзвичайна ситуація
                    </button>
                </ion-col>
            </ion-row>
            <ion-row>
                <ion-col style="text-align: center;padding: 30px;">
                    <img src="/assets/logo.jpeg">
                </ion-col>
            </ion-row>
        </ion-grid>
    </ion-content>
      <ion-modal :is-open="isOpenRef" @didDismiss="isOpenRef = false">
          <ion-row v-if="roles.includes('ROLE_POST_3')">
              <ion-col>
                  <button on-touch="goToNews(3)" type="button" class="button-native ion-color ion-color-primary button-type" @click="() => goToNews(3)">
                      Додати новину
                  </button>
              </ion-col>
          </ion-row>
          <ion-row v-if="roles.includes('ROLE_POST_5')">
              <ion-col>
                  <button on-touch="goToNews(5)" type="button" class="button-native ion-color ion-color-primary button-type" @click="() => goToNews(5)">
                      Додати відео
                  </button>
              </ion-col>
          </ion-row>
      </ion-modal>
  </ion-page>
</template>

<script>
import { IonPage, IonGrid, IonContent, IonRow, IonCol, IonModal } from '@ionic/vue';
import axios from "axios";
import {GET_USER_BUTTON} from "../constant/api";
import { useRouter } from 'vue-router';
import { useBackButton, useIonRouter } from '@ionic/vue';
import {Capacitor, Plugins} from '@capacitor/core';
import {AndroidPermissions} from "@ionic-native/android-permissions";
const { App } = Plugins;

export default  {
    name: 'Select',
    components: { IonGrid, IonContent, IonPage, IonRow, IonCol, IonModal },
    setup() {
        const ionRouter = useIonRouter();
        useBackButton(-1, () => {
            if (!ionRouter.canGoBack()) {
                App.exitApp();
            }
        });
        const router = useRouter();
        return { router };
    },
    data() {
        return {
            roles: [],
            isOpenRef: false,
            androidPermissions: [
                AndroidPermissions.PERMISSION.ACCESS_FINE_LOCATION,
                AndroidPermissions.PERMISSION.CAMERA,
                AndroidPermissions.PERMISSION.INTERNET,
                AndroidPermissions.PERMISSION.RECORD_AUDIO,
                AndroidPermissions.PERMISSION.READ_EXTERNAL_STORAGE,
                AndroidPermissions.PERMISSION.WRITE_EXTERNAL_STORAGE,
                AndroidPermissions.PERMISSION.ACCESS_FINE_LOCATION,
                AndroidPermissions.PERMISSION.ACCESS_COARSE_LOCATION,
            ]
        }
    },
    created() {
        this.getUsersButton();
        this.checkPerMision();
    },
    methods: {
        checkPerMision() {
            this.androidPermissions.forEach(function (item) {
                if(Capacitor.getPlatform() === 'android') {
                    AndroidPermissions.checkPermission(item).then(
                        result => {
                            console.log(item + ' ' + result.hasPermission);
                            if(!result.hasPermission) {
                                AndroidPermissions.requestPermission(item);
                            }
                            },
                        err => { alert(err); }
                    );
                }
            });
        },
        goToNews(type) {
            this.isOpenRef = false;
            this.router.push('/add-news/'+type);
        },

        getUsersButton() {
            const self = this;
            axios({
                method: 'POST',
                url: GET_USER_BUTTON,
                data: 'user_id=' + (localStorage.getItem('user') || ''),
                headers: {
                    'content-type': 'application/x-www-form-urlencoded;charset=utf-8',
                }
            }).then(res => {
                self.roles = res.data;
                console.log(self.roles);
            });
        }
    }
}
</script>

<style>

.ion-page {
    padding-top: 30px;
}

</style>
